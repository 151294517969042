<template>
    <div class="reviews-list full-width" v-if="translates && translates[langUrl]">
        <div class="reviews-list__main-image">
            <div class="reviews-list__main-image__opacity"></div>
            <div class="reviews-list__main-image__container">
                <!-- <div class="reviews-list__main-image__container__icon" /> -->
                <div class="reviews-list__main-image__container__text">
                    <div class="reviews-list__main-image__container__title">{{ translates[langUrl].pageTitle[lang] }}</div>
                    <div class="reviews-list__main-image__container__comment">{{ translates[langUrl].pageDescription[lang] }}</div>
                </div>
            </div>
        </div>
        <div class="reviews-list__main-container">
            <div class="reviews-list__title">{{ translates[langUrl].mainBlock_title[lang] }}</div>
            <div class="reviews-list__description">{{ translates[langUrl].mainBlock_description[lang] }}</div>
            <div class="reviews-list__reviews__items-container">
                <template v-for="(review, index) in reviews.items">
                    <ReviewMain
                        :key="'review_' + index"
                        :review="review"
                        :isDesktop="isDesktop"
                    />
                </template>
            </div>
            <BaseButton
                v-if="(this.page * 10) < reviews.totalCount"
                @click="showMore">{{ translates[langUrl].button_moreReviews[lang] }}</BaseButton>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { imageSrc, isMobile } from '@/helpers';
    import BaseButton from '../../components/common/BaseButton.vue'
    import moment from '@/plugins/moment';

    import ReviewMain from '../../components/common/ReviewMain.vue'


    export default {
        name: 'ReviewsList',
        metaInfo() {
            return {
                title: this.translates[this.langUrl].browserTitle[this.lang]
            }
        },
        components: {
            ReviewMain,
            BaseButton
        },
        data: () => ({
            imageSrc,
            isDesktop: true,
            langUrl: '/reviews',
            page: 1
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            ...mapState('news', {
                news: state => state.entities
            }),
            ...mapState('reviews', {
                reviews: state => state.entities
            }),
        },
        async mounted() {
            this.$root.$emit('preloaderShow');
            await this.getReviews();
            this.isDesktop = !isMobile
            window.addEventListener('scroll', this.checkPosition);
            // window.addEventListener('resize', checkPosition)
            this.$root.$emit('preloaderHide');
        },
        methods: {
            async getReviews() {
                await store.dispatch('reviews/fetch', { limit: 10, page: 1 });
            },

            // async getNews() {
            //     await store.dispatch('news/fetch', {});
            // },
            // postTime(date) {
            //     return moment(date).calendar()
            // },
            async open(code) {
                // await store.commit('news/CLEAR_ENTITY');
                await this.$router.push({ name: 'news', params: { code } });
            },
            async showMore() {
                if(this.page * 10 < this.reviews.totalCount) {
                    this.page = this.page + 1
                    await store.dispatch('reviews/fetch', { limit: (this.page * 10) });
                }
            },
            async checkPosition() {
                if(!this.loading) {
                    if(this.page * 10 < this.reviews.totalCount) {
                        this.loading = true;
                        const windowRelativeBottom = document.documentElement.getBoundingClientRect().bottom;
                        if (windowRelativeBottom < document.documentElement.clientHeight + 400) {
                            await this.showMore();
                        }
                        this.loading = false;
                    }
                }
            }
        }
    }

</script>

<style lang="scss">
    .reviews-list {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        &__main-image {
            position: relative;
            width: 100%;
            height: 440px;
            background-image: url(../../assets/ReviewHead.jpg);
            background-size: cover;
            background-position: center;
            overflow: hidden;
            @media all and (max-width: 768px) {
                height: 356px;
                background-position: center;
            }
            // &__opacity {
            //     position: absolute;
            //     width: 100%;
            //     height: 440px;
            //     background: #000000;
            //     opacity: 0.5;
            // }
            &__container {
                position: absolute;
                width: 100%;
                height: 440px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: #FFFFFF;
                @media all and (max-width: 768px) {
                    height: 356px;
                }
                &__text {
                    display: flex;
                    flex-direction: column;
                    width: 1280px;
                    @media all and (max-width: 768px) {
                        width: 100%;
                        padding: 20px;
                        margin: 0 auto;
                    }
                }
                &__title {
                    font-weight: bold;
                    font-size: 52px;
                    // margin-top: 245px;
                    line-height: 63px;
                    letter-spacing: -0.02em;
                    text-align: center;
                    margin-bottom: 12px;
                    @media all and (max-width: 768px) {
                        font-weight: 800;
                        font-size: 24px;
                        line-height: 150%;
                        margin-bottom: 15px;
                    }
                }
                &__comment {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 180%;
                    letter-spacing: -0.02em;
                    text-align: center;
                    @media all and (max-width: 768px) {
                        font-size: 16px;
                        line-height: 150%;
                        max-width: 300px;
                        margin: 0 auto;
                    }
                }
            }
        }
        &__main-container {
            max-width: 1280px;
            margin: 40px 24px 0;
            display: flex;
            flex-direction: column;
            @media all and (min-width: 1400px) {
                margin: 70px auto 0;
            }
            @media all and (max-width: 768px) {
                margin: 30px 20px 0;
            }
        }
        &__title{
            display: flex;
            justify-content: flex-start;
            font-weight: 700;
            font-size: 38px;
            line-height: 121%;
            letter-spacing: -0.02em;
            color: #273155;
            margin-bottom: 20px;
            @media all and (max-width: 768px) {
                font-size: 28px;
            }
        }
        &__description {
            display: flex;
            justify-content: flex-start;
            font-weight: 400;
            font-size: 18px;
            line-height: 180%;
            color: #273155;
            max-width: 1060px;
            margin-bottom: 80px;
            @media all and (max-width: 768px) {
                font-size: 16px;
                line-height: 140%;
                margin-bottom: 60px;
            }
        }
        &__reviews {
            &__items-container {
                display: flex;
                flex-direction: row;
                // max-height: 2000px;
                flex-wrap: wrap;
                margin: 0 0 40px;
                justify-content: space-between;
                @media all and(max-width: 768px) {
                    width: 100%;
                    // overflow: scroll;
                    overflow: visible;
                }
            }
        }
    }
</style>